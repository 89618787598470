body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Home > * + * {
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}

.SplashScreen {
  height: 100vh;
  width: 100vw;
  background: url(/static/media/promo.5b320b11.jpg) no-repeat center center fixed;
  background-position: center;
  color: white;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.SplashScreen .MainText {
  z-index: 1;
}

.SplashScreen .Overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
    to top,
    rgba(25, 99, 172, 0.7),
    rgba(25, 99, 172, 1)
  );

  opacity: 0.9;
}

.SplashScreen .Arrow {
  height: 20px;
  width: auto;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.LinkContainer {
  -webkit-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
  position: absolute;
  z-index: 10;
  width: auto;
  bottom: 30px;
  transition: bottom 0.5s;
}

.LinkContainer:hover {
  bottom: 40px;
}

.FlexContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.FlexItem {
  margin: 2vw;
}

/* Computer and Tablet */
@media screen and (min-width: 900px) {
  .MainText {
    width: 50vw;
  }
  .FlexItem {
    width: 20vw;
  }
}

/* Mobile */
@media screen and (max-width: 900px) {
  .MainText {
    width: 80%;
    font-size: 10px;
  }
  .FlexItem {
    width: 80vw;
  }
}

.FlexItem > * + * {
  margin: 1vw;
}

.ContactContainer > * + * {
  margin: 0.5vw;
}

.AboutContainer {
  margin-top: 10vh;
  margin-right: 15vw;
  margin-left: 15vw;
  margin-bottom: 10vh;
}

.AboutContainer div {
  margin-top: 1vw;
}

.FlexContainer {
  display: flex;
  align-items: center;
  grid-gap: 2vw;
  gap: 2vw;
}

.BeliefContainer {
  text-align: left;
}

/* Computer and Tablet */
@media screen and (min-width: 900px) {
  .FlexContainer {
    flex-wrap: nowrap;
  }
  .Image {
    width: 12vw;
  }
  .ImageDescription {
    width: auto;
  }
}

/* Mobile */
@media screen and (max-width: 900px) {
  .FlexContainer {
    flex-wrap: wrap;
  }
  .Image {
    width: 40vw;
  }
  .ImageDescription {
    width: 80vw;
  }
}

.EventsContainer {
  margin-top: 10vh;
  margin-right: 15vw;
  margin-left: 15vw;
  margin-bottom: 10vh;
}

.ConstructionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.navbar {
  background-color: #2c6fb4;
  height: 65px;
}

* {
  font-family: Avenir;
}

.App {
  text-align: center;
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

/* Computer and Tablet */
@media screen and (min-width: 900px) {
  .ContentScreen {
    padding-left: 20vw;
    padding-right: 20vw;
  }

  .MainText {
    font-size: 3rem;
  }

  .Header1 {
    font-size: 2rem;
    font-weight: bold;
  }

  .Header2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

/* Mobile */
@media screen and (max-width: 900px) {
  .MainText {
    font-size: 2rem;
  }

  .Header1 {
    font-size: 2rem;
    font-weight: bold;
  }

  .Header2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

