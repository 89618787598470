.Home > * + * {
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}

.SplashScreen {
  height: 100vh;
  width: 100vw;
  background: url("../../res/img/promo.jpg") no-repeat center center fixed;
  background-position: center;
  color: white;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.SplashScreen .MainText {
  z-index: 1;
}

.SplashScreen .Overlay {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
    to top,
    rgba(25, 99, 172, 0.7),
    rgba(25, 99, 172, 1)
  );

  opacity: 0.9;
}

.SplashScreen .Arrow {
  height: 20px;
  width: auto;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.LinkContainer {
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
  position: absolute;
  z-index: 10;
  width: auto;
  bottom: 30px;
  transition: bottom 0.5s;
}

.LinkContainer:hover {
  bottom: 40px;
}

.FlexContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.FlexItem {
  margin: 2vw;
}

/* Computer and Tablet */
@media screen and (min-width: 900px) {
  .MainText {
    width: 50vw;
  }
  .FlexItem {
    width: 20vw;
  }
}

/* Mobile */
@media screen and (max-width: 900px) {
  .MainText {
    width: 80%;
    font-size: 10px;
  }
  .FlexItem {
    width: 80vw;
  }
}

.FlexItem > * + * {
  margin: 1vw;
}

.ContactContainer > * + * {
  margin: 0.5vw;
}
