.EventsContainer {
  margin-top: 10vh;
  margin-right: 15vw;
  margin-left: 15vw;
  margin-bottom: 10vh;
}

.ConstructionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
